<template>
  <div class="hold-transition bg-frontera">
    <div class="container">
      <loading v-if="cargando" />
      <div class="wrapper" v-if="this.materiales[0]">
        <section class="content-fluid" style="height: 700px">
          <fieldset class="well card">
            <legend class="well-legend text-bold bg-info text-light">
              Material Evaluación
            </legend>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body p-0">
                    <table
                      class="table table-bordered table-striped table-hover table-sm text-nowrap"
                    >
                      <thead class="text-center">
                        <tr>
                          <th>Tipo</th>
                          <th>Descripción</th>
                          <th>Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="material in materiales" :key="material.data">
                          <td class="text-center">
                            {{
                              material.tipo == 1
                                ? "Pdf"
                                : material.tipo == 2
                                ? "Video"
                                : material.tipo == 3
                                ? "Imagen"
                                : null
                            }}
                          </td>
                          <td>{{ material.descripcion }}</td>
                          <td>
                            <div class="btn-group float-right">
                              <a
                                target="_blank"
                                :href="uri_docs + material.link"
                                type="button"
                                class="btn btn-sm btn-info"
                              >
                                <i class="fas fa-file"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  v-if="aprobado"
                  class="text-uppercase font-weight-bold text-center text-success"
                >
                  Aprobado
                </div>
                <div v-else class="text-uppercase font-weight-bold text-center">
                  Intentos Disponibles
                </div>
                <div class="card">
                  <div class="card-body table-responsive p-0">
                    <table
                      style="font-size: auto"
                      class="table table-bordered table-striped table-hover table-sm text-nowrap"
                    >
                      <thead class="text-center">
                        <tr>
                          <th>Intentos</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-for="position in intentos" :key="position">
                        <tr
                        >
                          <td
                            v-if="
                              (evaluacionesCond[position - 1] && continuar) ||
                                (!aprobado && !continuar) ||
                                (aprobado &&
                                  evaluacionesCond[position - 1] &&
                                  !continuar)
                            "
                            :class="
                              evaluacionesCond[position - 1] &&
                              evaluacionesCond[position - 1].resultado
                                ? 'text-uppercase font-weight-bold text-center text-gray'
                                : 'text-uppercase font-weight-bold text-center text-success'
                            "
                          >
                            Intento {{ position }}
                          </td>
                          <td
                            v-if="
                              (evaluacionesCond[position - 1] && continuar) ||
                                (!aprobado && !continuar) ||
                                (aprobado &&
                                  evaluacionesCond[position - 1] &&
                                  !continuar)
                            "
                          >
                            {{
                              evaluacionesCond[position - 1]
                                ? evaluacionesCond[position - 1].fecha_ini
                                : ""
                            }}
                          </td>
                          <td
                            v-if="
                              (evaluacionesCond[position - 1] && continuar) ||
                                (!aprobado && !continuar) ||
                                (aprobado &&
                                  evaluacionesCond[position - 1] &&
                                  !continuar)
                            "
                          >
                            {{
                              evaluacionesCond[position - 1]
                                ? evaluacionesCond[position - 1].fecha_fin
                                : ""
                            }}
                          </td>
                          <td
                            :class="
                              evaluacionesCond[position - 1].resultado &&
                              evaluacionesCond[position - 1].resultado >=
                                porcentaje
                                ? 'text-uppercase font-weight-bold text-center text-success'
                                : 'text-uppercase font-weight-bold text-center text-danger'
                            "
                            v-if="
                              evaluacionesCond[position - 1] &&
                                evaluacionesCond[position - 1].fecha_fin
                            "
                          >
                            {{ evaluacionesCond[position - 1].resultado }}
                          </td>
                          <td
                            class="text-center"
                            v-if="
                              !evaluacionesCond[position - 1] &&
                                !aprobado &&
                                !continuar
                            "
                          >
                            <button
                              type="button"
                              class="btn bg-primary col-md-12"
                              @click="evaluacion(position)"
                            >
                              Iniciar
                            </button>
                          </td>
                          <td
                            class="text-center"
                            v-if="
                              evaluacionesCond[position - 1] &&
                                !aprobado &&
                                !evaluacionesCond[position - 1].fecha_fin
                            "
                          >
                            <button
                              type="button"
                              class="btn bg-primary col-md-12"
                              @click="evaluacion(position)"
                            >
                              Continuar
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
const urlParams = new URLSearchParams(window.location.search);
const solicitud_id = urlParams.get("id");
const token = urlParams.get("tok");
import axios from "axios";
import moment from "moment";
import Loading from "../components/Loading";
export default {
  name: "MaterialEvaluacion",
  components: { Loading },
  data() {
    return {
      cargando: false,
      aprobado: false,
      continuar: null,
      porcentaje: null,
      materiales: {},
      intentos: 0,
      evaluacionCond: {},
      evaluacionesCond: [],
      fecha_ini: null,
      sesionDatos: [],
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    
    init() {
      this.cargando = true;
      if (solicitud_id) {
        this.solicitud_id = solicitud_id;
        axios
          .get("api/MaterialEvalaciones", {
            params: { solicitud_id: solicitud_id, codigo_verificacion: token },
          })
          .then((response) => {
            if (response.data[0]) {
              this.materiales = response.data;
              this.getIntentos(solicitud_id, token);
            } else {
              return this.$router.push({
                name: "Login",
              });
            }
          });
      }
      this.cargando = false;
    },

    getIntentos(solicitud_id, token) {
      axios
        .get("api/Evaluacion", {
          params: { solicitud_id: solicitud_id, codigo_verificacion: token },
        })
        .then((response) => {
          this.intentos = response.data.n_intentos;
          this.porcentaje = response.data.porcentaje;
          this.evaluacionesCond = response.data.evaluacionCond;
          if (this.evaluacionesCond) {
            let aprobacion = this.evaluacionesCond.find(
              (calificacion) => calificacion.resultado >= this.porcentaje
            );
            let continuacion = this.evaluacionesCond.find(
              (e) => e.fecha_fin == null
            );
            if (continuacion) {
              this.continuar = continuacion;
            }
            if (aprobacion) {
              this.aprobado = true;
            }
          }
        });
    },

    async evaluacion(position) {
      this.fecha_ini = await moment().format("YYYY-MM-DD HH:mm:ss");
      await axios
        .get("api/EvaluacionConductores", {
          params: {
            solicitud_id: this.solicitud_id,
            fecha_ini: this.fecha_ini,
          },
        })
        .then((response) => {
          this.evaluacionCond = response.data;
        });

      return this.$router.push({
        name: "EvaluacionConductor",
        params: {
          intento: position,
          evaluacion_id: this.materiales[0].evaluacion_id,
          materiales: this.materiales,
          evaluacionCond_id: this.evaluacionCond.id,
          solicitud_id: this.evaluacionCond.hse_solicitud_conductor_id,
          fecha_ini: this.evaluacionCond.fecha_ini,
          fecha_fin: this.evaluacionCond.fecha_fin,
          token: token,
        },
      });
    },
  },

  mounted() {
    this.init();
    this.toggleBodyClass("addClass", "sidebar-collapse");
    this.toggleBodyClass("removeClass", "sidebar-mini");
  },

  destroyed() {
    this.toggleBodyClass("removeClass", "sidebar-collapse");
    this.toggleBodyClass("addClass", "sidebar-mini");
  },
};
</script>

<style>
.login-block {
  background: #001871; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgb(248, 8, 8);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgb(248, 8, 8), 0 0 20px rgb(248, 8, 8);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 3px solid #09cce6;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  border: 2px solid #09cce6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
